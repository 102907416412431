import React from "react";
import styled from "styled-components";
import { Grid, List, Container } from "@mui/material";
import { FaInstagram, FaFacebook, FaYoutube, FaTiktok, FaLinkedin } from "react-icons/fa";
import YouTube from "react-youtube";
import Logo from "./assets/Logo.svg";
import whatsapp from "./assets/whatsapp.svg";
import globo from "./assets/globe-solid.svg";
import formulario from "./assets/Form.svg";

const SocialMediaIcons = [
    {
        name: "Instagram",
        icon: <FaInstagram width={40} height={40} />,
        link: "https://www.instagram.com/espanholessencia?igsh=MXFta2FpcTNjdjV4bw==",
    },
    {
        name: "Facebook",
        icon: <FaFacebook width={40} height={40} color="#3b5998" />,
        link: "https://www.facebook.com/espanholessencia",
    },
    {
        name: "Youtube",
        icon: <FaYoutube width={40} height={40} color="red" />,
        link: "https://www.youtube.com/channel/UCTFASDwJXKx9x4jyqTPE9NA",
    },
    {
        name: "TikTok",
        icon: <FaTiktok width={40} height={40} color="black" />,
        link: "https://www.tiktok.com/@espanholessencia",
    },
    {
        name: "Linkedin",
        icon: <FaLinkedin width={40} height={40} color="#0e76a8" />,
        link: "https://www.linkedin.com/company/espanholessencia",
    },
];

const ListItems = [
    {
        name: "Website",
        icone: <img src={globo} alt="icone de um globo" />,
        link: "https://www.espanholessencia.com",
        buttonName: "Meu Site",
    },
    {
        name: "Whatsapp",
        icone: <img src={whatsapp} alt="icone whatsapp" width={25} height={25} />,
        link: "https://api.whatsapp.com/send/?phone=5585994294206&text=Olá,+estou+vindo+através+do+seu+card+de+informações+que+está+no+instagram.+Gostaria+de+mais+informações+sobre+os+cursos+de+espanhol.&type=phone_number&app_absent=0",
        buttonName: "Whatsapp",
    },
    {
        name: "Lista de Espera",
        icone: <img src={formulario} alt="icone formulário" />,
        link: "https://forms.gle/MCHUkCwdYHXepKwFA",
        buttonName: "Lista de espera",
    },
];

const Titulo = styled.p`
    color: #000;
    font-size: 16px;
    @media (max-width: 600px) {
        font-size: 14px;
        text-align: center;
    }
`;

const ContainerIcons = styled.div`
    display: flex;
    justify-content: center;
    padding: 10px;
    margin-bottom: 20px;
`;

const ButtonIcon = styled.a`
    width: 40px;
    height: 40px;
    background: #cba06a;
    border: none;
    border-radius: 20px;
    color: #fef3d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin: 5px;

    &:hover {
        background: #cba06acc;
    }

    svg {
        font-size: 20px;
    }
`;

const Icone = styled.a`
    width: 24px;
    height: 24px;
    background: #cba06a;
    border: none;
    border-radius: 15px;
    color: #fef3d9;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
`;

const ListItem = styled.li`
    border-radius: 20px;
    background-color: #cba06a;
    height: 60px;
    cursor: pointer;
    margin-bottom: 10px;
    border: solid black;
    width: auto;
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    box-shadow: #cbc 5px 5px 5px;
    color: black;
    font-weight: bold;

    @media (max-width: 600px) {
        box-sizing: border-box;
        padding: 10px;
    }

    &:hover {
        background: #cba06acc;
    }
`;

const ListItemButton = styled.div`
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    padding: 10px;
    font-size: 24px;
    color: white;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50px;
    flex-wrap: nowrap;
    justify-content: center;

    @media (max-width: 600px) {
        font-size: 20px;
    }
`;

const ButtonName = styled.p`
    flex-grow: 0;
    text-align: center;

    @media (max-width: 600px) {

}
`;

const StyledContainer = styled(Container)`
    max-width: 100% !important;


    @media (max-width: 600px) {
        padding: 10px;
    }
`;

const StyledGrid = styled(Grid)`
    box-shadow: border-box;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
    border: solid;
    border-radius: 25px;
    padding: 50px 0px 10px;
    width: inherit;

    @media (max-width: 600px) {
        padding: 10px 10px;
        margin: 10px 0;
    }
`;

function SinglePage() {
    return (
        <StyledContainer className="um" style={{       
            display: "flex",
            flexDirection: "column",
            alignItems: "center"}}>
            <StyledGrid container spacing={0} style ={{flexDirection: "column",
            width: "100%",
            maxWidth: "680px"}}>
                    <div>
                        <img src={Logo} alt="Logo" width={376} style={{ maxWidth: "100%" }} />
                        <Titulo>Siga nossas redes sociais: </Titulo>
                    </div>
                <Grid item xs={12}>
                    <ContainerIcons>
                        {SocialMediaIcons.map((socialMedia, index) => (
                            <ButtonIcon href={socialMedia.link} target="_blank" key={index}>
                                {socialMedia.icon}
                            </ButtonIcon>
                        ))}
                    </ContainerIcons>
                </Grid>
                <Grid item style={{width:"400px"}} xs={12}>
                    <List>
                        {ListItems.map((item, index) => (
                            <a href={item.link} target="_blank" rel="noreferrer" style={{ textDecoration: "none", display:"flex", flexDirection:"column" }} key={index}>
                                <ListItem>
                                    <ListItemButton>
                                        <Icone>{item.icone}</Icone>
                                    </ListItemButton>
                                    <ButtonName>{item.buttonName}</ButtonName>
                                </ListItem>
                            </a>
                        ))}
                    </List>
                </Grid>
            </StyledGrid>
            <p>
                Desenvolvido por:{" "}
                <a href="https://api.whatsapp.com/send/?phone=5585991352841&text=Ol%C3%A1,+v%C3%AD+o+Linktree+que+voc%C3%AA+criou+e+gostei.+Como+fa%C3%A7o+para+ter+um?&type=phone_number&app_absent=0">
                    Giórgio Almeida
                </a>
                ©
            </p>
        </StyledContainer>
    );
}

export default SinglePage;
