import './App.css';

import SinglePage from './SinglePage';

function App() {
  return (
    <div className="App">
    <SinglePage/>
    </div>
  );
}

export default App;
